import React from 'react';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Segment from 'utils/segment'
import { graphql, Link } from 'gatsby';
import BlogLayout from 'components/common/blog/BlogLayout';

import PostListCarousel from 'templates/Blog/common/PostList/Carousel';
import Container from 'react-bootstrap/Container';
import PostThumbnail from 'templates/Blog/common/PostThumbnail';
import Blocks from 'templates/Blog/Gutenberg/Blocks';
import Breadcrumb, { blogBreadcrumbs } from 'templates/Blog/common/Breadcrumb';
import './styles.sass';

class Home extends React.Component {

  render() {
    const {
      data: { page, recentPosts, featuredPosts },
    } = this.props;
    const recentPostsNodes = recentPosts.nodes;
    const latestArticle = recentPostsNodes[0];

    return (
      <BlogLayout showLinksInFooter={false} showM6Banner>
        <CharlesMeta
          title="Trouvez la réponse à vos questions sur le blog de Charles.co"
          description="Nos médecins répondent à toutes vos questions sur les problèmes sexuels, psychologiques, digestifs, de sommeil et de chute de cheveux. Découvrez les symptômes et traitements conseillés."
          segmentCategory={Segment.CATEGORIES.BLOG}
        />
        <Container className="blog-home">
          <div className="">
            <Breadcrumb links={blogBreadcrumbs()} />
          </div>
          <h1 className="h1-blog">Blog de Charles.co</h1>
          <h2 className="h2-blog">Dernier article</h2>
          <div className="latest-article">
            <PostThumbnail post={latestArticle} />
          </div>
          <PostListCarousel
            title="Les plus lus"
            showCategory
            posts={featuredPosts.nodes}
            className="h2"
          />
          <PostListCarousel
            title="Les plus récents"
            showCategory
            posts={recentPostsNodes}
            className="h2"
          />
        </Container>
        <div className="why-this-blog">
          <Container>
            <Blocks context={page} blocks={page.blocks} />
          </Container>
        </div>
      </BlogLayout>
    );
  }
}

export default Home;

export const pageQuery = graphql`
  query BlogHomeById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      fields {
        structuredDatas
      }
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      link
      slug
      status

      featuredImage {
        node {
          ...BlogImage
        }
      }
      blocks {
        ...GutenbergBlock
      }
    }
    featuredPosts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { eq: "home" } } } } }
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
    recentPosts: allWpPost(
      limit: 7
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { name: { ne: "Newsletter" } } } }
      }
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
  }

  fragment BlogImageThumbnail on WpMediaItem {
    altText
    localFile {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: DOMINANT_COLOR)
      }
    }
  }

  fragment BlogPostThumbnail on WpPost {
    author {
      node {
        firstName
        lastName
        name
        slug
        uri
      }
    }
    excerpt
    title
    uri
    categories {
      nodes {
        slug
        name
      }
    }
    id
    modified
    status
    featuredImage {
      node {
        ...BlogImageThumbnail
      }
    }
  }
`;
